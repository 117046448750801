<template>
  <v-container fluid class="down-top-padding">
    <BaseBreadcrumb :title="page.title" :icon="icons.domain"></BaseBreadcrumb>

    <v-progress-linear
      v-if="isCompanyFetching"
      indeterminate
      class="progress-linear-validate"
    ></v-progress-linear>

    <p class="mt-5 ml-1 text-h6">Información</p>
    <v-card v-if="myCompany && !isCompanyFetching" class="pa-2 mt-n2">
      <v-card-title class="text-h6">
        <v-row align="center">
          {{ myCompany.customerName }}

          <v-btn icon @click="onEditCompanyDialogClick">
            <v-icon class="mb-1"> {{ icons.pencil }}</v-icon>
          </v-btn>
        </v-row>
      </v-card-title>
      <v-card-subtitle v-if="myCompany.nif">
        <v-row align="center" class="mt-2"> NIF: {{ myCompany.nif }} </v-row>
      </v-card-subtitle>
      <v-card-title>
        <v-row align="center" class="mt-2"
          >Periodo de custodia de documentos</v-row
        >
      </v-card-title>
      <v-card-subtitle>
        <v-row align="center" class="mt-2">
          {{ formattedCustodyPeriod }}
        </v-row>
      </v-card-subtitle>
      <v-row no-gutters class="pa-2">
        <v-btn
          color="primary"
          @click="onEditRoleUsersDialogClick"
          outlined
          class="ma-2 btn--expanded"
        >
          <v-icon left>
            mdi-account-convert
          </v-icon>
          GESTIONAR USUARIOS ADMIN
        </v-btn>

        <v-btn
          color="primary"
          @click="onEditInvitedUsersDialogClick"
          outlined
          class="ma-2 btn--expanded"
        >
          <v-icon left>
            mdi-account-edit
          </v-icon>
          GESTIONAR INVITACIONES
        </v-btn>
      </v-row>
    </v-card>

    <p class="mt-5 ml-1 text-h6">Equipos</p>
    <v-card>
      <v-data-table
        class="mt-n2"
        :headers="companyTeamsHeaders"
        :items="companyTeams"
        hide-default-footer
        disable-pagination
        :loading="isCompanyTeamsFetching"
        disable-sort
      >
        <template v-slot:top>
          <v-row no-gutters class="pa-2">
            <v-btn
              @click="onAddTeamClick"
              color="primary"
              class="ma-2 btn--expanded"
            >
              <v-icon left>mdi-plus</v-icon>CREAR EQUIPO</v-btn
            >
            <v-spacer></v-spacer>
            <v-btn
              outlined
              @click="onCreated"
              color="primary"
              class="ma-2 btn--expanded"
              ><v-icon left>mdi-refresh</v-icon>REFRESCAR</v-btn
            >
          </v-row>
        </template>

        <template v-slot:[`item.actions`]="{ item }">
          <v-icon @click="editTeam(item)"> {{ icons.pencil }} </v-icon>
          <v-icon class="ml-2" @click="editTeamUsers(item)">
            {{ icons.users }}
          </v-icon>
          <v-icon class="ml-2" @click="onInviteTeamUserClick(item)">
            {{ icons.addUser }}
          </v-icon>
        </template>

        <template v-slot:[`item.teamTokenId`]="{ item }">
          <div>
            <span>{{ item.teamTokenId }}</span>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  v-on="on"
                  class="ml-2"
                  medium
                  @click="onClickCopyId(item.teamTokenId)"
                >
                  mdi-content-copy
                </v-icon>
              </template>
              <span>Copiar</span>
            </v-tooltip>
          </div>
        </template>
      </v-data-table>
    </v-card>

    <v-dialog scrollable width="600" v-model="companyDialog" persistent>
      <v-card class="pa-1">
        <v-card-title class="text-h5">Editar Empresa </v-card-title>
        <v-card-text>
          <v-text-field
            class="mt-2"
            v-model="editedCompany.customerName"
            outlined
            dense
            label="Nombre"
          ></v-text-field>

          <v-text-field
            class="mt-2"
            v-model="editedCompany.nif"
            outlined
            dense
            label="NIF"
          ></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="companyDialog = false"
            >Cancelar</v-btn
          >

          <v-btn
            color="primary"
            :loading="isEditCompanyLoading"
            text
            @click="updateCompany"
            >Actualizar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog scrollable width="600" v-model="teamDialog" persistent>
      <v-card class="pa-1">
        <v-card-title class="text-h5">Editar Equipo</v-card-title>
        <v-card-text>
          <v-text-field
            class="mt-2"
            v-model="editedTeam.name"
            outlined
            dense
            label="Nombre"
          ></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="teamDialog = false"
            >Cancelar</v-btn
          >
          <v-btn
            color="primary"
            :loading="isEditTeamLoading"
            text
            @click="updateTeam"
            >Actualizar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog scrollable width="600" v-model="teamUsersDialog" persistent>
      <v-card class="pa-1">
        <v-card-title class="text-h5">Gestionar Usuarios Equipo</v-card-title>
        <v-card-subtitle>{{ editedTeam.name }}</v-card-subtitle>
        <v-card-text>
          <v-progress-linear
            v-if="isCompanyUsersFetching"
            indeterminate
            class="progress-linear-validate"
          ></v-progress-linear>

          <v-data-table
            disable-sort
            item-key="email"
            v-model="selectedTeamUsers"
            :show-select="!isCompanyUsersFetching && companyUsers.length > 0"
            v-if="!isCompanyUsersFetching"
            :headers="teamUsersHeaders"
            :items="companyUsers"
            hide-default-footer
            disable-pagination
          >
          </v-data-table>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="teamUsersDialog = false"
            >Cancelar</v-btn
          >
          <v-btn
            color="primary"
            :loading="isTeamUsersLoading"
            :disabled="isCompanyUsersFetching"
            text
            @click="updateTeamUsers"
            >Aceptar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog scrollable width="600" v-model="inviteTeamUserDialog" persistent>
      <v-card class="pa-1">
        <v-card-title class="text-h5">Invitar Usuario</v-card-title>
        <v-card-subtitle>{{ editedTeam.name }}</v-card-subtitle>
        <v-card-text>
          <v-text-field
            class="mt-2"
            v-model="invitedUser.email"
            outlined
            dense
            label="Email de usuario"
          ></v-text-field>

          <v-text-field
            v-model="invitedUser.firstName"
            outlined
            dense
            label="Nombre"
          ></v-text-field>

          <v-text-field
            v-model="invitedUser.lastName"
            outlined
            dense
            label="Apellidos"
          ></v-text-field>

          <v-alert border="left" colored-border type="info" elevation="2">
            Todas las personas que invites a este equipo tendrán rol de usuario
            básico.
          </v-alert>

          <div class="mt-4">
            <li
              class="red--text text--darken-1"
              v-for="(error, index) in inviteUserEmailErrors"
              :key="index"
            >
              {{ error }}
            </li>
          </div>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="inviteTeamUserDialog = false"
            >Cancelar</v-btn
          >
          <v-btn
            color="primary"
            :loading="isInviteTeamUserLoading"
            text
            @click="inviteTeamUser"
            >Invitar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog scrollable width="600" v-model="addTeamDialog" persistent>
      <v-card class="pa-1">
        <v-card-title class="text-h5">Crear Equipo</v-card-title>
        <v-card-text>
          <v-text-field
            class="mt-2"
            v-model="addTeamName"
            outlined
            dense
            label="Nombre de equipo"
          ></v-text-field>

          <div class="mt-4">
            <li
              class="red--text text--darken-1"
              v-for="(error, index) in addTeamErrors"
              :key="index"
            >
              {{ error }}
            </li>
          </div>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="addTeamDialog = false"
            >Cancelar</v-btn
          >
          <v-btn
            color="primary"
            :loading="isAddTeamLoading"
            text
            @click="addTeam"
            >Crear</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog scrollable width="600" v-model="roleUsersDialog" persistent>
      <v-card class="pa-1">
        <v-card-title class="text-h5 text-sm-small"
          >Gestionar Usuarios Admin</v-card-title
        >

        <v-card-text>
          <v-progress-linear
            v-if="isCompanyUsersFetching"
            indeterminate
            class="progress-linear-validate"
          ></v-progress-linear>

          <v-data-table
            disable-sort
            item-key="email"
            v-model="selectedAdminUsers"
            :show-select="!isCompanyUsersFetching && companyUsers.length > 0"
            v-if="!isCompanyUsersFetching"
            :headers="teamUsersHeaders"
            :items="companyUsersButMe"
            hide-default-footer
            disable-pagination
          >
          </v-data-table>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="roleUsersDialog = false"
            >Cancelar</v-btn
          >
          <v-btn
            color="primary"
            :loading="isTeamUsersLoading"
            :disabled="isCompanyUsersFetching"
            text
            @click="updateRoleUsers"
            >Aceptar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      scrollable
      width="600"
      v-model="notConfirmedUsersDialog"
      persistent
    >
      <v-card class="pa-1">
        <v-card-title class="text-h5"
          >Gestionar Invitaciones Sin Confirmar</v-card-title
        >

        <v-card-text>
          <v-progress-linear
            v-if="isCompanyUsersFetching || isUserInvitationDialogLoading"
            indeterminate
            class="progress-linear-validate"
          ></v-progress-linear>

          <v-data-table
            v-if="!isUserInvitationDialogLoading"
            disable-sort
            item-key="email"
            :headers="noConfirmedUsersHeaders"
            :items="notConfirmedUsers"
            hide-default-footer
            disable-pagination
          >
            <template v-slot:[`item.actions`]="{ item }">
              <v-icon @click="resendUserInvitation(item)">
                {{ icons.resend }}
              </v-icon>
              <v-icon class="ml-2" @click="cancelUserInvitation(item)">
                {{ icons.remove }}
              </v-icon>
            </template>
          </v-data-table>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="notConfirmedUsersDialog = false"
            >Cerrar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import {
  mdiAccountMultiple,
  mdiAccountPlus,
  mdiAccountRemove,
  mdiDomain,
  mdiEmailSend,
  mdiPencil
} from "@mdi/js";

export default {
  data: () => ({
    icons: {
      domain: mdiDomain,
      pencil: mdiPencil,
      users: mdiAccountMultiple,
      addUser: mdiAccountPlus,
      resend: mdiEmailSend,
      remove: mdiAccountRemove
    },
    page: {
      title: "Mi Empresa"
    },
    isCompanyFetching: false,
    companyDialog: false,
    isEditCompanyLoading: false,
    editedCompany: {},
    isCompanyTeamsFetching: false,
    companyTeamsHeaders: [
      {
        text: "NOMBRE",
        value: "name"
      },
      {
        text: "ID",
        value: "teamTokenId"
      },
      { text: "ACCIONES", value: "actions" }
    ],
    teamUsersHeaders: [
      {
        text: "NOMBRE",
        value: "fullName"
      },
      {
        text: "EMAIL",
        value: "email"
      }
    ],
    teamDialog: false,
    isEditTeamLoading: false,
    editedTeam: {},

    isCompanyUsersFetching: false,
    isTeamUsersLoading: false,
    teamUsersDialog: false,
    selectedTeamUsers: [],

    inviteTeamUserDialog: false,
    isInviteTeamUserLoading: false,
    invitedUser: {},
    inviteUserEmailErrors: [],

    addTeamDialog: false,
    addTeamErrors: [],
    isAddTeamLoading: false,
    addTeamName: "",

    roleUsersDialog: false,
    selectedAdminUsers: [],

    notConfirmedUsersDialog: false,
    noConfirmedUsersHeaders: [
      {
        text: "NOMBRE",
        value: "fullName"
      },
      {
        text: "EMAIL",
        value: "email"
      },
      { text: "ACCIONES", value: "actions" }
    ],
    isUserInvitationDialogLoading: false
  }),

  created() {
    this.onCreated();
  },

  methods: {
    onCreated() {
      this.fetchMyCompany();
      this.fetchCompanyTeams();
      this.fetchCompanyUsers();
    },

    onAddTeamClick() {
      (this.addTeamName = ""), (this.addTeamDialog = true);
    },

    addTeam() {
      this.addTeamErrors = [];
      if (!this.addTeamName) {
        this.addTeamErrors.push("Introduce un nombre válido");
      } else {
        this.isAddTeamLoading = true;
        this.$store
          .dispatch("addTeam", { name: this.addTeamName })
          .then(() => {
            this.isAddTeamLoading = false;
            this.addTeamDialog = false;
            this.$toasted.global.toast_success({
              message: "Los cambios se han guardado correctamente"
            });
          })
          .catch(err => {
            console.log(err);
            this.isAddTeamLoading = false;
            this.$toasted.global.toast_error({
              message: "No se ha podido crear el equipo"
            });
          });
      }
    },

    updateRoleUsers() {
      this.isTeamUsersLoading = true;
      var selectedEmails = this.selectedAdminUsers.map(user => user.email);

      this.$store
        .dispatch("putAdminUsers", {
          teamTokenId: this.editedTeam.teamTokenId,
          emails: selectedEmails
        })
        .then(() => {
          this.isTeamUsersLoading = false;
          this.roleUsersDialog = false;
          this.$toasted.global.toast_success({
            message: "Los cambios se han guardado correctamente"
          });
        })
        .catch(err => {
          console.log(err);
          this.isTeamUsersLoading = false;
          this.$toasted.global.toast_error({
            message: "No se han podido modificar los roles de los usuarios"
          });
        });
    },

    updateTeamUsers() {
      this.isTeamUsersLoading = true;
      var selectedEmails = this.selectedTeamUsers.map(user => user.email);

      this.$store
        .dispatch("putTeamUsers", {
          teamTokenId: this.editedTeam.teamTokenId,
          emails: selectedEmails
        })
        .then(() => {
          this.isTeamUsersLoading = false;
          this.teamUsersDialog = false;
          this.$toasted.global.toast_success({
            message: "Los cambios se han guardado correctamente"
          });
        })
        .catch(err => {
          console.log(err);
          this.isTeamUsersLoading = false;
          this.$toasted.global.toast_error({
            message: "No se han modificar los usuarios del equipo"
          });
        });
    },

    onInviteTeamUserClick(team) {
      this.invitedUser = {};
      this.editedTeam = JSON.parse(JSON.stringify(team));
      this.inviteTeamUserDialog = true;
    },

    inviteTeamUser() {
      this.inviteUserEmailErrors = [];
      if (!/.+@.+\..+/.test(this.invitedUser.email)) {
        this.inviteUserEmailErrors.push("Introduce un email válido");
      } else if (
        !this.invitedUser.firstName ||
        this.invitedUser.firstName.length < 4
      ) {
        this.inviteUserEmailErrors.push(
          "Introduce un nombre válido. Al menos 4 caracteres."
        );
      } else if (
        !this.invitedUser.lastName ||
        this.invitedUser.lastName.length < 2
      ) {
        this.inviteUserEmailErrors.push(
          "Introduce un apellido válido. Al menos 2 caracteres."
        );
      } else {
        this.isInviteTeamUserLoading = true;
        this.invitedUser.teamTokenId = this.editedTeam.teamTokenId;

        this.$store
          .dispatch("inviteNewUser", this.invitedUser)
          .then(() => {
            this.isInviteTeamUserLoading = false;
            this.inviteTeamUserDialog = false;
            this.invitedUser = {};
            this.$toasted.global.toast_success({
              message: "Usuario invitado correctamente"
            });
          })
          .catch(err => {
            console.log(err);
            this.isInviteTeamUserLoading = false;
            this.$toasted.global.toast_error({
              message: "No se ha podido invitar al usuario"
            });
          });
      }
    },

    editTeam(team) {
      this.editedTeam = JSON.parse(JSON.stringify(team));
      this.teamDialog = true;
    },

    editTeamUsers(team) {
      this.selectedUserIds = [];
      this.editedTeam = JSON.parse(JSON.stringify(team));
      this.fetchCompanyUsers();
      this.teamUsersDialog = true;
    },

    fetchMyCompany() {
      this.isCompanyFetching = true;
      this.$store
        .dispatch("getMyCompany")
        .then(() => {
          this.isCompanyFetching = false;
        })
        .catch(err => {
          console.log(err);
          this.$toasted.global.toast_error({
            message: "No se han podido recuperar los datos de la empresa"
          });
          this.isCompanyFetching = false;
        });
    },
    fetchCompanyTeams() {
      this.isCompanyTeamsFetching = true;
      this.$store
        .dispatch("getCompanyTeams")
        .then(() => {
          this.isCompanyTeamsFetching = false;
        })
        .catch(err => {
          console.log(err);
          this.$toasted.global.toast_error({
            message: "No se han podido recuperar los equipos de la empresa"
          });
          this.isCompanyTeamsFetching = false;
        });
    },

    fetchCompanyUsers() {
      this.isCompanyUsersFetching = true;
      this.selectedTeamUsers = [];
      this.selectedAdminUsers = [];
      this.$store
        .dispatch("getCompanyUsers")
        .then(() => {
          this.isCompanyUsersFetching = false;
          this.selectedTeamUsers = this.companyUsers.filter(user => {
            var teamTokenIds = user.teams.map(team => team.teamTokenId);
            return teamTokenIds.includes(this.editedTeam.teamTokenId);
          });

          this.selectedAdminUsers = this.companyUsers.filter(user => {
            return user.isCustomerAdmin;
          });
        })
        .catch(err => {
          console.log(err);
          this.$toasted.global.toast_error({
            message: "No se han podido recuperar los usuarios de la empresa"
          });
          this.isCompanyUsersFetching = false;
        });
    },

    onEditInvitedUsersDialogClick() {
      this.notConfirmedUsersDialog = true;
    },

    onEditRoleUsersDialogClick() {
      this.fetchCompanyUsers();
      this.roleUsersDialog = true;
    },

    onEditCompanyDialogClick() {
      this.companyDialog = true;
      this.editedCompany = JSON.parse(JSON.stringify(this.myCompany));
    },

    updateCompany() {
      this.isEditCompanyLoading = true;
      this.$store
        .dispatch("putMyCompany", this.editedCompany)
        .then(response => {
          if (response.status === 204) {
            this.processSuccessEditCompanyResponse();
          } else {
            this.processErrorEditCompanyResponse();
          }
        })
        .catch(error => {
          console.log(error);
          this.processErrorEditCompanyResponse();
        });
    },
    updateTeam() {
      this.isEditTeamLoading = true;
      this.$store
        .dispatch("putTeam", this.editedTeam)
        .then(response => {
          this.editedTeam = {};
          if (response.status === 204) {
            this.processSuccessEditTeamResponse();
          } else {
            this.processErrorEditTeamResponse();
          }
        })
        .catch(error => {
          console.log(error);
          this.processErrorEditTeamResponse();
        });
    },

    processSuccessEditTeamResponse() {
      this.isEditTeamLoading = false;
      this.teamDialog = false;
      this.$toasted.global.toast_success({
        message: "Los cambios se han guardado correctamente"
      });
    },

    processErrorEditTeamResponse() {
      this.isEditTeamLoading = false;
      this.$toasted.global.toast_error({
        message: "Los cambios no se han podido guardar correctamente"
      });
    },

    processSuccessEditCompanyResponse() {
      this.isEditCompanyLoading = false;
      this.companyDialog = false;
      this.$toasted.global.toast_success({
        message: "Los cambios se han guardado correctamente"
      });
    },

    processErrorEditCompanyResponse() {
      this.isEditCompanyLoading = false;
      this.$toasted.global.toast_error({
        message: "Los cambios no se han podido guardar correctamente"
      });
    },

    onClickCopyId(id) {
      navigator.clipboard.writeText(id);
      this.$toasted.global.toast_success({
        message: "Se ha copiado el identificador del equipo al portapapeles"
      });
    },

    resendUserInvitation(item) {
      this.isUserInvitationDialogLoading = true;
      this.$store
        .dispatch("resendUserInvitation", item.email)
        .then(() => {
          this.isUserInvitationDialogLoading = false;
          this.$toasted.global.toast_success({
            message: "Invitación reenviada correctamente"
          });
        })
        .catch(err => {
          console.log(err);
          this.isUserInvitationDialogLoading = false;
          this.$toasted.global.toast_error({
            message: "No se ha podido reenviar la invitación"
          });
        });
    },

    cancelUserInvitation(item) {
      this.isUserInvitationDialogLoading = true;
      this.$store
        .dispatch("cancelUserInvitation", item.email)
        .then(() => {
          this.isUserInvitationDialogLoading = false;
          this.$toasted.global.toast_success({
            message: "Invitación cancelada correctamente"
          });
        })
        .catch(err => {
          console.log(err);
          this.isUserInvitationDialogLoading = false;
          this.$toasted.global.toast_error({
            message: "No se ha podido cancelar la invitación"
          });
        });
    },
    convertDaysToYearsMonthsDays(days) {
      const years = Math.floor(days / 365);
      days %= 365;
      const months = Math.floor(days / 30);
      days %= 30;
      return { years, months, days };
    }
  },
  computed: {
    formattedCustodyPeriod() {
      const { years, months, days } = this.convertDaysToYearsMonthsDays(
        this.myCompany.custodyPeriod
      );
      let result = "";

      if (years > 0) {
        result += `${years} año${years > 1 ? "s" : ""}`;
      }
      if (months > 0) {
        if (result) result += ", ";
        result += `${months} mes${months > 1 ? "es" : ""}`;
      }
      if (days > 0) {
        if (result) result += ", y ";
        result += `${days} día${days > 1 ? "s" : ""}`;
      }

      return result || "0 días";
    },
    myCompany() {
      return this.$store.getters.myCompany;
    },
    companyTeams() {
      return this.$store.getters.companyTeams;
    },

    notConfirmedUsers() {
      return this.$store.getters.companyUsers.filter(
        user => user.emailConfirmed === false
      );
    },

    companyUsers() {
      return this.$store.getters.companyUsers;
    },
    companyUsersButMe() {
      return this.$store.getters.companyUsers.filter(
        user => user.email != this.$store.getters["user/userProfile"].email
      );
    }
  }
};
</script>
<style scoped>
@media (max-width: 460px) {
  .btn--expanded {
    width: 95%;
  }
  .text-sm-small {
    font-size: 1rem !important;
  }
}
</style>
